import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Divider, Form, FormInstance, Input } from 'antd';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { passwordRules } from '@lib/passwordRules';
import useRuntimeConfig from '@vl-core/hooks/useConfig';
import Hint from '@components/Login/Hint';
import Align from '@components/UI/utilities/Align';
import IdentityProviderButtons from '@components/Login/IdentityProviderButtons';

type LoginViewProps = {
  /** The Ant Design form instance */
  form: FormInstance<any>;
  /** A submit handler for the login form */
  onFinish: (values: any) => void;
  /** Loading state */
  loading: boolean;
};

const LoginView: React.FC<LoginViewProps> = ({ form, onFinish, loading }) => {
  const { IDENTITY_PROVIDERS, SELF_REG, LUCY_ENV, PROD_PATIENT_URL } = useRuntimeConfig();
  const selfRegEnabled = SELF_REG === 'true';
  const { t } = useTranslation('common');
  const { query, pathname } = useRouter();

  const isProd = LUCY_ENV.includes('prod');

  const { username: paramUsername } = query;
  const isWellbeing = pathname === '/wellbeinghub';

  const getSignUpQuery = () => {
    const signUpQuery: Record<string, string | string[]> = {
      ...query,
      source_url: sessionStorage.getItem('source_url') || window.location.href
    };

    if (isWellbeing) signUpQuery.self_refer = '1';

    return signUpQuery;
  };

  // eslint-disable-next-line quotes
  const forgotYourPassword = t("Don't have a HBSUK account? ");

  return (
    <>
      {!isProd && PROD_PATIENT_URL && (
        <Alert
          type="warning"
          message="This is a test environment"
          description={
            <div>
              {t('You are currently on a test environment.')}
              <a tabIndex={0} href={PROD_PATIENT_URL}>
                {t('The correct website can be found here')}
              </a>
              .
            </div>
          }
          css={{ marginBottom: 20, width: '100%' }}
          closable
          showIcon
        />
      )}
      {selfRegEnabled && (
        <Hint paddingBottom>
          {forgotYourPassword}
          <Link
            href={{ pathname: './patients/register', query: getSignUpQuery() }}
            className="sign-up-link sign-up-prompt"
          >
            Sign Up
          </Link>
        </Hint>
      )}

      <Form name="basic" form={form} layout="vertical" {...{ onFinish }}>
        <Form.Item
          label="Your Email Address"
          name="username"
          initialValue={paramUsername}
          validateTrigger="onSubmit"
          rules={[
            {
              type: 'email',
              message: 'Please input your e-mail address!'
            },
            {
              required: true,
              message: 'Please input your e-mail address!'
            }
          ]}
        >
          <Input type="email" data-testid="login-email-input" size="large" autoComplete="off" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          validateTrigger="onSubmit"
          rules={passwordRules}
          extra={<Link href="/forgot-password">Forgot your password?</Link>}
        >
          <Input.Password data-testid="login-password-input" autoComplete="off" />
        </Form.Item>

        <Form.Item>
          <Align x="right">
            <Button type="primary" data-testid="log-in" htmlType="submit" size="large" {...{ loading }}>
              Log in
            </Button>
          </Align>
        </Form.Item>
      </Form>

      {IDENTITY_PROVIDERS?.providers && IDENTITY_PROVIDERS?.providers?.length > 0 && (
        <>
          <Divider>or</Divider>
          {/* eslint-disable-next-line test-selectors/button */}
          <IdentityProviderButtons />
        </>
      )}

      <Hint paddingTop />
    </>
  );
};

export default LoginView;
