import React from 'react';
import Hint from '@components/Login/Hint';
import IdentityProviderButtons from '@components/Login/IdentityProviderButtons';
import { useRouter } from 'next/router';
import { ParsedQuery } from 'query-string';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Alert, Button } from 'antd';
import useIsNHS from '@hooks/useIsNHS';
import { useRuntimeConfig } from 'vl-common/src/hooks/Runtime';

type SSOViewProps = {
  setPageView: (value: string) => void;
};

interface SSOViewQueryParams extends ParsedQuery {
  optOut: '0' | '1';
}

const SSOView: React.FC<SSOViewProps> = ({ setPageView }) => {
  const { t } = useTranslation('common');
  const isNHS = useIsNHS();
  const { CONTACT_US_URL, COGNITO, LUCY_ENV, PROD_PATIENT_URL } = useRuntimeConfig();
  const isProd = LUCY_ENV.includes('prod');

  const { pathname, push, query } = useRouter();
  const { optOut } = query as SSOViewQueryParams;

  const setOptOut = (value: boolean) => {
    if (value) query.optOut = '1';
    else delete query.optOut;

    push({ pathname, query });
  };

  if (Number(optOut)) {
    return (
      <>
        <p>{t('NHSLoginOptedOut')}</p>
        <p>For the safety of your medical data, this is the only way we allow patients to register for the service.</p>
        <p>
          If you would like to talk to a member of our team before registering, please{' '}
          <a href="https://www.hbsuk.co.uk/contact-us" data-test-id="contact-us-link">
            contact us
          </a>
          .
        </p>
        <p>
          <Button type="link" onClick={() => setOptOut(false)} data-testid="opt-in-link">
            Return to login page
          </Button>
        </p>
      </>
    );
  }

  return (
    <div>
      {isNHS && (
        <>
          <p>Access to this service is via NHS login. The link below will direct you to either:</p>

          <ul
            css={css`
              padding-inline-start: 1.5rem;

              li {
                list-style-type: none;
                position: relative;
              }

              li::before {
                content: '•';
                position: absolute;
                left: -1rem;
                font-weight: 600;
                color: var(--productPrimary);
              }
            `}
          >
            <li>Create an NHS login account</li>
            <li>Log in to your existing NHS login account</li>
          </ul>

          <p>We use this method of authentication to keep your medical information secure.</p>
        </>
      )}
      {!isProd && PROD_PATIENT_URL && (
        <Alert
          type="warning"
          message="This is a test environment"
          description={
            <div>
              {t('You are currently on a test environment.')}
              <a tabIndex={0} href={PROD_PATIENT_URL}>
                {t('The correct website can be found here')}
              </a>
              .
            </div>
          }
          css={{ marginBottom: 20, width: '100%' }}
          closable
          showIcon
        />
      )}
      {/* eslint-disable-next-line test-selectors/button */}
      <IdentityProviderButtons />

      <Hint paddingTop>
        <span
          css={css`
            width: 100%;
          `}
        >
          {COGNITO && (
            <>
              <Button
                data-testid="more-login-options"
                css={css`
                  font-weight: 600;
                  padding: 0;
                `}
                type="link"
                onClick={() => setPageView('SSO')}
              >
                {t('More login options')}
              </Button>

              <span> · </span>
            </>
          )}
          <a data-testid="contact-us" href={CONTACT_US_URL} target="_blank" rel="noreferrer" css={css``}>
            {t('Need more help?')}
          </a>
        </span>
      </Hint>
    </div>
  );
};

export default SSOView;
