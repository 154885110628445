import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Alert, Button, Form } from 'antd';
import * as actions from '@actions';
import * as actionTypes from '@actionTypes';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import { Auth } from 'aws-amplify';
import { useInitialisedStore } from '@hooks/useInitialisedStore';
import { usePolicies } from '@hooks/usePolicies';
import { useRuntimeConfig } from '@vl-core/hooks/useConfig';
import { infoModal } from 'vl-common/src/components/Modals';
import LoginLayout from '@components/Layouts/Login';
import useSetTourAttributes from '@hooks/useSetTourAttributes';
import useBrowserCheckCallback from '@hooks/useBrowserCheckCallback';
import useAuth from '@vl-core/useAuth';
import LoginView from '@components/Login/views/LoginView';
import SSOView from '@components/Login/views/SSOView';
import MFAView from '@components/Login/views/MFAView';
import NewPasswordView from '@components/Login/views/NewPasswordView';
import saveAuthAndRedirect, { getIsLocalhostOrNgrok } from 'vl-common/src/lib/saveAuthAndRedirect';
import { Maintenance } from 'vl-common/src/components/Maintenance';
import { css } from '@emotion/react';
import { useDeepLink } from '@hooks/useDeepLink';
import { BVT_LOGIN_PATH } from './hbsbvt';
import { tryGetUser } from '../store/actions/auth';
import { requestSuccess } from '../store/actions/action';

// Function to reverse a character shifted string
const unshiftString = (str: string, shiftBy: number) =>
  str
    .split('')
    .map((char) => String.fromCharCode(char.charCodeAt(0) - shiftBy))
    .join('');

function maybePersistCredentials() {
  try {
    const o = JSON.parse(decodeURI(window.location.hash.slice(1)));

    // since this originated from sessionStorage (whose values are all strings) this generic type is reasonable
    Object.entries<string>(o)
      .filter(([, v]) => typeof v === 'string')
      .map(([k, v]) => [unshiftString(k, 3), v])
      .filter(([k]) =>
        ['accessToken', 'clockDrift', 'idToken', 'refreshToken', 'userData', 'LastAuthUser'].find((p) => k.endsWith(p))
      )
      .forEach(([k, v]) => window.sessionStorage.setItem(k, v));

    window.location.hash = '';
    window.location.reload();
  } catch (e) {
    // don't care
  }
}

const Login = () => {
  const { IDENTITY_PROVIDERS, APP_CLIENT_ID, GOOGLE_ANALYTICS_TAG } = useRuntimeConfig();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { pushToApp } = useDeepLink();

  // normally I'd take the url parameters from the router, but it seems that on its first
  // call the parameters are null
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  const isOptOut = urlParams.error_description?.includes('ConsentNotGiven');

  const { pageView: initialPageView = 'sso', userdata, error_description, ...additionalMetaData } = urlParams;

  const router = useRouter();
  const [pageView, setPageView] = useState(initialPageView);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userModel, setUserModel] = useState<{ username?: string }>({});
  const [alert, setAlert] = useState<
    Partial<{
      code: number;
      message: string | ReactNode;
      type: 'info' | 'success' | 'error' | 'warning';
    }>
  >({});
  useInitialisedStore();
  const providers = IDENTITY_PROVIDERS?.providers;
  const [cognitoUser, setCognitoUser] = useState();
  const { t } = useTranslation('common');
  const { policies, policiesAccepted } = usePolicies();
  const { user, hasSignedOut } = useAuth();
  const setTourAttributes = useSetTourAttributes();
  const browserCheckCallback = useBrowserCheckCallback();
  const source_url = useQueryParam('source_url', withDefault(StringParam, null));
  const autoSSO = useQueryParam('autosso', withDefault(StringParam, null));
  const [amplifyConfigured, setAmplifyConfigured] = useState(false);

  // call once *before* returning from the first render
  useState(() => maybePersistCredentials());

  useEffect(() => {
    const checkNHSPoliciesDeclined = () => {
      const searchParams = new URLSearchParams(router.asPath.split('?')[1]);
      const urlParams = Object.fromEntries(searchParams.entries());
      if (urlParams.error_description === 'ConsentNotGiven; error=access_denied') {
        setPageView('sso');
        router.replace({ query: { optOut: 1 } }).then();
      }
    };
    checkNHSPoliciesDeclined();
  }, [router, router.asPath]);

  useEffect(() => {
    if (source_url?.[0]) {
      sessionStorage.setItem('source_url', source_url[0]);
    }
  }, [source_url]);

  // Either fallback to a SSO provider derived from the error description, or remove error_description
  useEffect(() => {
    if (error_description && providers) {
      const fallbackProviderName = `-ERR-${error_description.split(':')[0]}`;
      const handlerProvider = providers.find((p) => p?.provider?.includes(fallbackProviderName));

      if (handlerProvider) {
        // @ts-ignore
        Auth.federatedSignIn({ provider: handlerProvider.provider }).then();
        return;
      }

      const { search, origin, pathname } = window.location;
      const searchParams = new URLSearchParams(search);

      searchParams.delete('error_description');
      window.history.pushState({}, '', `${origin}${pathname}?${searchParams.toString()}`);
    }
  }, [error_description, providers]);

  useEffect(() => {
    if (alert) {
      const timeout = setTimeout(() => setAlert({}), 5 * 1000);
      return () => clearInterval(timeout);
    }
    return () => {};
  }, [alert]);

  const stringifiedMeta = Object.keys(additionalMetaData).length ? JSON.stringify(additionalMetaData) : null;

  useEffect(() => {
    Auth.configure({
      userPoolWebClientId: APP_CLIENT_ID,
      ...(stringifiedMeta && {
        clientMetadata: { clientOrigin: window.location.origin, ...JSON.parse(stringifiedMeta) }
      })
    });
    setAmplifyConfigured(true);
  }, [APP_CLIENT_ID, stringifiedMeta]);

  useEffect(() => {
    if (autoSSO?.[0] && providers && amplifyConfigured) {
      const autoSsoProvider = providers?.find((p) => p?.provider === autoSSO[0]);
      if (autoSsoProvider) {
        // @ts-ignore
        Auth.federatedSignIn({ provider: autoSsoProvider.provider });
      }
    }
  }, [autoSSO, providers, amplifyConfigured]);

  if (isOptOut) {
    router.replace({ query: { optOut: 1 } });
  }
  const getIsUserOnProperHost = (loginUrl: string): boolean => {
    if (!loginUrl) {
      // if login_url is not set, assume the user has logged into the correct URL
      return true;
    }
    return new URL(loginUrl).hostname === window.location.hostname;
  };

  const authLoginSuccess = useCallback(async (res, router, policies) => {
    if (Auth.authenticatedUser) return;

    if (!('user' in res)) return;

    browserCheckCallback(true).then();

    sessionStorage.removeItem('case-filter-selected');

    const { user_type_code, needs_card_details, self_reg_required, login_url = '' } = res.user;

    if (!['PATIENT', 'CLINICIAN'].includes(user_type_code)) {
      // incorrect user type
      await Auth.signOut({ global: true });
      await pushToApp(login_url);
      return;
    }

    const isUserOnProperHost = getIsUserOnProperHost(login_url);
    const isLocalhostOrNgrok = getIsLocalhostOrNgrok();

    // if the user has logged into the wrong host redirect them to proper host
    if (!isUserOnProperHost && !isLocalhostOrNgrok) {
      await saveAuthAndRedirect(login_url);
      return;
    }

    // drops the last segment of the pathname, i.e. "login" (this route) and remove empty segments
    const pathnameSegments = window.location.pathname
      .split('/')
      .filter((segment) => segment)
      .slice(0, -1);
    // todo - when story branches work with SSO, get this set correctly
    const ssoRedirectToStoryBranch = false;

    const getTargetPage = () => {
      switch (true) {
        case !policies.policiesAccepted:
          return 'policy';
        case self_reg_required:
          return 'create-account';
        case needs_card_details:
          return 'patients/profile';
        case user_type_code === 'CLINICIAN':
          return 'clinician';
        case user_type_code === 'PATIENT':
          return 'patients';
        default:
          throw Error('Could not work out where to navigate to');
      }
    };

    if (ssoRedirectToStoryBranch) {
      window.location.href = `/${[...pathnameSegments, getTargetPage()].join('/')}`;
    } else {
      await pushToApp(`/${getTargetPage()}`);
    }
  }, []);

  useEffect(() => {
    if (!providers?.length) {
      setPageView('login');
    }
  }, [providers]);

  useEffect(() => {
    const tempEdgeCaseUsername = sessionStorage.getItem('newUserForgotPassRedirect');
    if (tempEdgeCaseUsername) {
      sessionStorage.removeItem('newUserForgotPassRedirect');
      infoModal({
        title: 'Temporary Password Required',
        content: (
          <>
            We have just emailed you a copy of your temporary password. To login for the first time please login with
            your email address and temporary password, and you will then be prompted to enter a permanent password.
            Click OK to be redirected to the login page.
          </>
        )
      });
    }
    if (sessionStorage.getItem('passwordResetSuccess')) {
      setAlert({
        type: 'success',
        message: 'Your password has been successfully reset. You may now log in.'
      });
      sessionStorage.removeItem('passwordResetSuccess');
    }

    async function maybeNavigateToDashboard() {
      const sUrl = sessionStorage.getItem('source_url');
      const user = await tryGetUser(sUrl || null);

      if (user) {
        await authLoginSuccess({ user }, router, { policies, policiesAccepted });
        setTourAttributes().then();
        if (
          (localStorage.getItem('cookie-consent') === 'all' || localStorage.getItem('cookie-consent') === 'per') &&
          GOOGLE_ANALYTICS_TAG &&
          !Auth.authenticatedUser
        ) {
          if (sessionStorage.getItem('ga-configured') === 'true') {
            document.cookie = `_ga_${GOOGLE_ANALYTICS_TAG.replace(
              'G-',
              ''
            )}=; path=/; domain=.virtuallucyweb.co.uk; expires=${new Date(0).toUTCString()}`;
          }
          const options: { campaign_source?: string; campaign_id?: string } = {};
          if (sUrl) options.campaign_source = sUrl;
          if (user.client_name) options.campaign_id = user.client_name.replace(/\s/g, '-');
          ReactGA.initialize(GOOGLE_ANALYTICS_TAG, {
            gaOptions: { ...options }
          });

          sessionStorage.setItem('ga-configured', 'true');
          sessionStorage.setItem('campaign_id', user.client_name.replace(/\s/g, '-'));
        }

        sessionStorage.removeItem('source_url');
      }

      // avoid a race condition
      const timedLogout = async () => {
        try {
          await Auth.currentSession();
        } catch {
          dispatch(actions.userLogout());
          Auth.signOut({ global: true });
        }
      };

      setTimeout(timedLogout, 300);
    }

    maybeNavigateToDashboard().then();
  }, [authLoginSuccess, dispatch, policies, policiesAccepted, router]);

  useEffect(() => {
    if (policies?.length > 0) {
      authLoginSuccess({ user }, router, { policies, policiesAccepted });
    }
  }, [policies, user, router, policiesAccepted, authLoginSuccess]);

  useEffect(() => {
    if (!hasSignedOut) {
      // browser check is called to notify the user if they need to update their browser
      browserCheckCallback().then();
    }
  }, []);

  useEffect(() => {
    if (hasSignedOut) {
      dispatch(requestSuccess(actionTypes.AUTH_SIGNED_OUT_SUCCESS));
    }
  }, [hasSignedOut]);

  const onUsernameSubmit = useCallback(
    async (data) => {
      setButtonLoading(true);
      try {
        const cog = await Auth.signIn(data);
        const res = await dispatch(
          actions.userLogin(data, { source_url: sessionStorage.getItem('source_url') || window.location.href }, cog)
        );
        setCognitoUser(cog);
        setButtonLoading(false);
        setAlert({});
        setUserModel(data);
        if (res.type === actionTypes.AUTH_LOGIN_CODE) {
          setPageView('code');
        } else if (res.type === actionTypes.AUTH_LOGIN_NEWPASSOWRD) {
          setPageView('newPassword');
        } else {
          console.log('Unhandled results type', { res });
        }
      } catch (err) {
        console.log({ err });
        if (err.code === 'PasswordResetRequiredException') {
          sessionStorage.setItem('passwordReset', data.username);
          router.push('/forgot-password').then();
        } else {
          setAlert({
            type: 'error',
            message: err.message
          });
        }
      } finally {
        setButtonLoading(false);
      }
    },
    [dispatch, router]
  );

  const refreshPage = () => {
    window.location.reload();
  };

  const onSubmitSendCode = useCallback(
    async (data) => {
      setButtonLoading(true);
      try {
        const { code } = data;
        await dispatch(
          actions.userSendCode(
            code.trim(),
            { source_url: sessionStorage.getItem('source_url') || window.location.href },
            cognitoUser
          )
        );
        sessionStorage.removeItem('source_url');
      } catch (err) {
        if (err.code === 'NotAuthorizedException') {
          setAlert({
            type: 'error',
            code: err.code,
            message: (
              <>
                <p css={{ marginBottom: '0.5rem' }}>This code has expired.</p>

                <Button data-testid="login-again" type="link" onClick={refreshPage}>
                  Log in again
                </Button>
              </>
            )
          });
        } else if (err?.type === 'AUTH_FAIL') {
          setAlert({
            type: 'error',
            code: err?.type,
            message: 'Invalid code specified'
          });
        } else if (err?.type === 'AUTH_SESSION_EXPIRED') {
          setAlert({
            type: 'error',
            code: err?.type,
            message: 'Credentials expired, please cancel and login again'
          });
        } else {
          console.log({ err });
          setAlert({
            type: 'error',
            code: err.code,
            message: 'An unknown error occured'
          });
        }
        setButtonLoading(false);
      }
    },
    [cognitoUser, dispatch]
  );

  const onResetPasswordConfirm = useCallback(
    async (data) => {
      setButtonLoading(true);
      try {
        const { password } = data;
        const cog = userdata ? JSON.parse(atob(userdata)) : cognitoUser;
        const res = await dispatch(actions.userNewPassword(password, cog), false);

        if (res.type === actionTypes.AUTH_LOGIN_NEWPASSWORD_SUCCESS) {
          setButtonLoading(false);
          onUsernameSubmit({
            username: userModel.username || cog?.challengeParam.userAttributes.email,
            password
          }).then();
        } else {
          const msg = await res.error?.json();
          const message = msg?.errorMessage || 'Sorry, this password cannot be reused.';

          setAlert({
            type: 'error',
            message: /^\d{3} - /.test(message) ? message.slice(6) : message
          });
          setButtonLoading(false);
        }
      } catch (err) {
        setAlert({
          type: 'error',
          code: err.code,
          message: err.message
        });
        setButtonLoading(false);
      }
    },
    [cognitoUser, dispatch, onUsernameSubmit, userdata, userModel.username]
  );

  const resendCode = useCallback(async () => {
    try {
      await Auth.signOut();

      setCognitoUser(
        await Auth.signIn({
          username: form.getFieldValue('username'),
          password: form.getFieldValue('password')
        })
      );

      infoModal({
        title: 'Identity Confirmation Code',
        content: 'Your identity confirmation code has been resent'
      });
    } catch (e) {
      console.error(e);
      infoModal({
        title: 'Unexpected Response',
        content: 'An unexpected response was received. Please try again later.'
      });
    }
  }, [form]);

  const onMFACancel = useCallback(() => {
    setPageView('login');
  }, [setPageView]);

  const pageViewIsInvalidValue = (page) => {
    return page !== 'login' && page !== 'code' && page !== 'sso' && page !== 'newPassword';
  };

  if (error_description) return null;

  return (
    <LoginLayout title={t('Login')}>
      {alert.message && (
        <Alert
          css={css`
            margin-bottom: 10px;
          `}
          message={alert.message}
          type={alert.type}
          showIcon
          closable
          onClose={() => setAlert({})}
        />
      )}
      {(pageView === 'login' || pageViewIsInvalidValue(pageView)) && (
        <LoginView onFinish={onUsernameSubmit} loading={buttonLoading} {...{ form }} />
      )}
      {pageView === 'code' && (
        <MFAView
          onFinish={onSubmitSendCode}
          loading={buttonLoading}
          resendCode={resendCode}
          onCancel={onMFACancel}
          username={userModel.username}
          cognitoUser={cognitoUser}
        />
      )}
      {pageView === 'sso' && <SSOView setPageView={setPageView} />}
      {pageView === 'newPassword' && (
        <NewPasswordView
          onFinish={onResetPasswordConfirm}
          loading={buttonLoading}
          username={userModel.username}
          {...{ form }}
        />
      )}
    </LoginLayout>
  );
};

function WrappedLogin() {
  const { SYSTEM_MODE } = useRuntimeConfig();
  const { pathname } = useRouter();

  if (!window) {
    return null;
  }

  if (SYSTEM_MODE === 'bvt' && !pathname.includes(BVT_LOGIN_PATH)) return <Maintenance />;

  return <Login />;
}

export default WrappedLogin;
