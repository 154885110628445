import React from 'react';
import useRuntimeConfig from '@vl-core/hooks/useConfig';
import { Button, Form, Input } from 'antd';
import AsyncButton from '@components/UI/AsyncButton/AsyncButton';
import { css } from '@emotion/react';

type MFAViewProps = {
  onFinish: (values: any) => void;
  loading: boolean;
  resendCode: () => void;
  onCancel: () => void;
  username: string;
  cognitoUser: any;
};

const MFAView: React.FC<MFAViewProps> = ({ onFinish, loading, resendCode, onCancel, username, cognitoUser }) => {
  const { MFA_LENGTH, MFA_SECS } = useRuntimeConfig();
  const [mfaForm] = Form.useForm();

  React.useEffect(() => {
    function windowFocused() {
      // @ts-ignore
      window.code?.focus();
    }
    window.addEventListener('focus', windowFocused);

    return () => window.removeEventListener('focus', windowFocused);
  }, []);

  return (
    <>
      <p>
        We’ve sent you an email or text message with a {MFA_LENGTH}-digit security code using your preferred contact
        method (email or text message).
      </p>
      <Form form={mfaForm} layout="vertical" {...{ onFinish }}>
        <Form.Item
          label="Code"
          name="code"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: `Please input your ${MFA_LENGTH} digit code!`
            }
          ]}
        >
          <Input autoComplete="off" autoFocus inputMode="numeric" />
        </Form.Item>

        <div
          css={css`
            display: flex;
            justify-content: end;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.5rem;
          `}
        >
          <Button type="default" size="large" htmlType="button" onClick={onCancel} data-testid="cancel">
            Cancel
          </Button>

          <AsyncButton
            size="large"
            htmlType="button"
            onClick={resendCode}
            disableInitially
            delay={Number(MFA_SECS) || 60}
            data-testid="resend-code"
          >
            <AsyncButton.Button data-testid="resent">Resend code</AsyncButton.Button>
            <AsyncButton.TimeLeft data-testid="countdown" type="Email/SMS" />
          </AsyncButton>

          <Button type="primary" size="large" htmlType="submit" {...{ loading }} data-testid="submit">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};

export default MFAView;
