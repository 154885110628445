import Password from '@components/FormInputs/Password';
import Align from '@components/UI/utilities/Align';
import { Button, Form, FormInstance } from 'antd';
import React, { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';

const ConfirmPassword = dynamic(() => import('@components/FormInputs/ConfirmPassword'));

type NewPasswordViewProps = {
  /** The Ant Design form instance */
  form: FormInstance<any>;
  /** A submit handler for the new password form */
  onFinish: (values: any) => void;
  /** Loading state */
  loading: boolean;
  /** The username of the user */
  username: string;
};

const NewPasswordView: React.FC<NewPasswordViewProps> = ({ form, onFinish, loading, username }) => {
  const [password, setPassword] = useState('');

  const onConfirmPasswordChanged = useCallback(
    (value: string) => {
      form.setFields([
        {
          name: 'confirmpassword',
          value
        }
      ]);
    },
    [form]
  );
  const onPasswordChanged = useCallback(
    (val: string) => {
      form.setFieldsValue({
        password: val
      });
      setPassword(val);
    },
    [form]
  );
  return (
    <>
      <p>Please now set a password of your choice.</p>
      <p>
        Email address: <span>{username}</span>
      </p>

      <Form layout="vertical" form={form} {...{ onFinish }}>
        <Form.Item
          label="New Password"
          name="password"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
        >
          <Password onChange={onPasswordChanged} />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmpassword"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              }
            })
          ]}
        >
          <ConfirmPassword onChange={onConfirmPasswordChanged} password={password} />
        </Form.Item>

        <Form.Item>
          <Align x="right">
            <Button type="primary" size="large" htmlType="submit" {...{ loading }} data-testid="sign-in">
              Sign in
            </Button>
          </Align>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewPasswordView;
